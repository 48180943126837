<template>
  <div class="contact-form-container">
    <FormSuccess v-if="submitted" class="container" />
    <form v-else action="#" @submit.prevent="submitContactForm">
      <FormError :error="error" />

      <div class="field-group">
        <div class="field">
          <label for="name">Name<span>*</span></label>
          <input type="text" id="name" v-model="fields.fromName" required />
        </div>

        <div class="field">
          <label for="phoneNumber">Phone</label>
          <input
            type="tel"
            id="phoneNumber"
            v-model="fields.message.phoneNumber"
          />
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label for="email">Email<span>*</span></label>
          <input type="email" id="email" v-model="fields.fromEmail" required />
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label for="message">Message<span>*</span></label>
          <textarea
            id="message"
            v-model="fields.message.body"
            rows="4"
            required
          ></textarea>
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <button v-if="submitting" disabled>
            <FontAwesomeIcon icon="spinner" spin />
            SUBMITTING
          </button>
          <button v-else>SUBMIT</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { stringify } from 'qs'
import FormError from '@/components/Forms/FormError'
import FormSuccess from '@/components/Forms/FormSuccess'
export default {
  name: 'ContactForm',
  components: { FormSuccess, FormError },
  props: {
    subject: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      fields: {
        action: 'contact-form/send',
        subject: 'Contact Form Submission',
        fromName: '',
        fromEmail: '',
        message: {
          phoneNumber: '',
          body: '',
        },
      },
      error: '',
      submitting: false,
      submitted: false,
    }
  },
  methods: {
    submitContactForm() {
      if (this.submitting) return

      this.submitting = true
      this.error = ''

      if (window.Craft) {
        this.fields[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue
      }

      if (this.subject && this.subject.length) {
        this.fields.subject = this.subject
      }

      const axiosConfig = {
        baseURL: '/',
        headers: {
          Accept: 'application/json',
        },
      }

      this.$axios
        .post('/', stringify(this.fields), axiosConfig)
        .then(() => {
          this.submitted = true
        })
        .catch((error) => {
          const response = error.response

          if (response && response.data && response.data.error) {
            this.error = response.data.error
          } else {
            this.error =
              'Something went wrong while trying to submit the form. Please try again.'
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-form-container {
  @apply mt-10;

  form {
    @apply mx-auto;
    max-width: 600px;

    .field-group {
      @apply flex flex-wrap;

      .field {
        @apply w-full px-4 py-2;

        label {
          @apply block text-left pb-1;
          font-size: 18px;
          color: #cfcfcf;

          span {
            color: #d76b00;
          }
        }

        input,
        textarea {
          @apply block w-full px-2 py-1;
          border: 1px solid #cfcfcf;
          background-color: #4f4f4f;
          color: #cfcfcf;
          border-radius: 12px;

          &:focus {
            border: 1px solid #d76b00;
            outline: none;
          }
        }

        button {
          @apply px-2 py-1;
          border: 1px solid #cfcfcf;
          background-color: #f17800;
          color: #ffffff;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          border-radius: 12px;
          transition: background-color 0.3s ease-in;

          &:hover {
            background-color: #be5f00;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    form {
      .field-group {
        @apply flex-nowrap;
      }
    }
  }
}
</style>
